.PartnerItemDetailModal {
  .ant-form-item {
    margin-bottom: 8px;
  }

  .card {
    background-color: #000000;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .image {
    width: 150px;
    height: 200px;
    text-align: center;
    padding: 8px;
    margin: 0 8px 8px 0;
    float: left;
    border-radius: 4px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .image-display {
  }

  .image-hide {
  }
}