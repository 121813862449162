.ant-layout-content {
  //background: #fff;
  //padding: 24px;
  //min-height: 280px;
}

.ant-input.static {
  background: #ffffff;
  color: inherit;
  border: none;
  cursor: default;
}

.ant-tooltip {
  max-width: 350px;
}

.ant-btn {
  font-weight: bold;
}

.resize-none textarea {
  resize: none;
}

.single-line textarea {
  height: 32px;
}