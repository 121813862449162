@font-face {
  font-family: 'Arita-dotum-Medium';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/Arita-dotum-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: 'Arita-dotum-Medium', sans-serif;
}
//@import "https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css";
//body {
//  font-family: 'NanumSquare', sans-serif;
//}