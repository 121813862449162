.CategoryTree {
  .node {
    padding: 2px 0;
    cursor: pointer;

    .title:hover {
      text-decoration: underline;
    }
  }
  .node:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .depth-0 {
    margin-top: 32px;
  }
  .depth-1 {
    margin-left: 1rem;
  }
  .depth-2 {
    margin-left: 1rem;
  }
}