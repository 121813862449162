.markdown {
  p {
    margin-bottom: 1rem;
  }
}

.markdown-body {
  ul {
    list-style: disc;
  }
}